import "./App.css";
import "./assest/css/chatbot.css";
import ChatbotHome from "./chatbot";
// import Chatbot from "./chatbot/chatbot";

function App() {
  return (
    <div className="App">
      {/* <header className="App-header"> */}
      <ChatbotHome />
      {/* <Chatbot /> */}
      {/* </header> */}
    </div>
  );
}

export default App;
